import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/builder"
import { CoreFields } from "./fragment" // eslint-disable-line
import { CoreBlockFragment } from "../components/blocks/CoreBlock" // eslint-disable-line
import Breadcrumb from "../components/Breadcrumb"
import CookiesConsent from "../components/CookiesConsent"

class CaseStudy extends Component {
  render() {
    this.props.pageContext.pageTitle = this.props.data.wpCaseStudy.Meta.title
      ? this.props.data.wpCaseStudy.Meta.title
      : this.props.data.wpCaseStudy.title
    this.props.pageContext.date = this.props.data.wpCaseStudy.date
    this.props.pageContext.cats = this.props.data.wpCaseStudy.categories
    const blocks = buildBlocks(
      this.props.data.wpCaseStudy.blocks,
      {},
      this.props.pageContext
    )

    return (
      <Layout
        meta={{
          ...this.props.data.wpCaseStudy.Meta,
          ogImage: this.props.data.wpCaseStudy.featuredImage?.node.localFile
            .childImageSharp.fixed.src,
          twitterImage: this.props.data.wpCaseStudy.featuredImage?.node
            .localFile.childImageSharp.fixed.src,
        }}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpCaseStudy.title}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="case-study"
          current={{
            title: this.props.data.wpCaseStudy.title,
            slug: this.props.data.wpCaseStudy.slug,
            uri: this.props.data.wpCaseStudy.uri,
          }}
        />
        {blocks}
        <CookiesConsent></CookiesConsent>
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String) {
    wpCaseStudy(id: { eq: $id }) {
      id
      slug
      title
      date(formatString: "DD MMMM YYYY")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fixed(width: 300, height: 200) {
                src
              }
            }
          }
        }
      }
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default CaseStudy
